import React, { useState } from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import COLORS from '../constants/colors'
import SubPageHeroBanner from '../components/SubPageHeroBanner'
import TextArea from '../components/TextArea'
import { Grid, makeStyles } from '@material-ui/core'
import FONTS from '../constants/fonts'
import carreerImg from '../images/vow-career.jpg'
import cecilie from '../images/Cecilie-jonassen-and-team.jpg'
import { FormatQuote } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 900,
    },
  },
  contentContainer: {
    height: 'fit-content',
    backgroundColor: COLORS.WHITE,
    justifyContent: 'space-evenly',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    },
  },
  openPositionsContainer: {
    height: 'fit-content',
    justifyContent: 'space-evenly',
    backgroundColor: '#F2F3F7',
    paddingBottom: 40,
  },
  submitApplicationContainer: {
    height: 'fit-content',
    justifyContent: 'space-evenly',
    backgroundColor: '#F2F3F7',
    paddingTop: 20,
    backgroundColor: COLORS.COLE,
  },
  applicationForm: {
    backgroundColor: COLORS.COLE,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      // paddingLeft: 20,
    },
  },
  text: {
    fontWeight: FONTS.WEIGHT.MEDIUM,
    lineHeight: 1,
    marginBlockEnd: '0px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 20px 0px 20px',
    },
  },
  description: {
    fontSize: 26,
    fontWeight: FONTS.WEIGHT.LIGHT,
    [theme.breakpoints.down('sm')]: {
      fontSize: FONTS.SIZE.XM,
    },
  },
  positionsHeader: {
    fontSize: 38,
    marginTop: 36,
    width: 300,
    fontWeight: FONTS.WEIGHT.MEDIUM,
    [theme.breakpoints.down('sm')]: {
      fontSize: FONTS.SIZE.XXM,
      marginBottom: 0,
    },
  },
  sectionLeft: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    maxWidth: 300,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
      maxWidth: '100%',
      paddingTop: 36,
    },
  },
  sectionRight: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0,
      height: 300,
    },
  },
  sectionRightEmpty: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0,
      height: 60,
    },
  },
  careerImg: {
    width: 560,
    [theme.breakpoints.down('md')]: {
      width: 400,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  careerImg2: {
    width: 560,
    [theme.breakpoints.down('md')]: {
      width: 400,
    },
  },
  linkAnchor: {
    target: '_blank',
    rel: 'noopener noreferrer',
    textDecoration: 'none',
  },
  linkText: {
    marginBlockStart: '2px',
    color: COLORS.GREEN,
    width: 550,
    [theme.breakpoints.down('md')]: {
      width: 400,
    },
    [theme.breakpoints.down('sm')]: {
      width: 350,
      paddingLeft: 20,
    },
  },
  quoteContainer: {
    height: 'fit-content',
    justifyContent: 'space-evenly',
    backgroundColor: '#F2F3F7',
    paddingBottom: 40,
  },
  quoteSectionLeft: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0,
      height: 300,
    },
  },
  quote: {
    fontSize: 28,
    marginTop: 36,
    lineHeight: 1,
    fontWeight: FONTS.WEIGHT.LIGHT,
    [theme.breakpoints.down('sm')]: {
      fontSize: FONTS.SIZE.XXM,
      marginBottom: 0,
    },
  },
  quoteSectionright: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0,
      height: 300,
      paddingLeft: '2%',
      paddingright: '2%',
    },
  },
  quoteDetail: {
    lineHeight: 1,
    margin: 0,
  },
  text: {
    fontWeight: FONTS.WEIGHT.MEDIUM,
    lineHeight: 1,
    [theme.breakpoints.down('sm')]: {
      padding: '0px 20px 0px 20px',
    },
  },
  textAlt: {
    fontWeight: FONTS.WEIGHT.MEDIUM,
    color: 'white',
    lineHeight: 1,
    [theme.breakpoints.down('sm')]: {
      padding: '0px 20px 0px 20px',
    },
  },
  header: {
    fontSize: 38,
    marginTop: 36,
    maxWidth: 300,
    fontWeight: FONTS.WEIGHT.MEDIUM,
    [theme.breakpoints.down('sm')]: {
      fontSize: FONTS.SIZE.XXM,
      marginBottom: 0,
    },
  },
  applyContainer: {
    backgroundColor: COLORS.COLE,
    width: '100%',
    height: 260,
    paddingBottom: 40,
    alignItems: 'center',
    '@media (max-width:600px)': {
      height: 500,
      paddingBottom: 40,
    },
  },
  input: {
    border: '1px solid black',
    width: '100%',
    height: 40,
    maxWidth: 320,
    paddingLeft: 15,
    outline: 'none',
    '@media (max-width:600px)': {
      maxWidth: 286,
      marginLeft: 20,
    },
  },
  message: {
    border: '1px solid black',
    width: '100%',
    maxWidth: 360,
    fontFamily: 'Roboto',
    resize: 'none',
    outline: 'none',
    height: 112,
    marginTop: 10,
    '@media (max-width:600px)': {
      maxWidth: 300,
      marginLeft: 20,
    },
  },
  containerSubmit: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '40px',
    alignItems: 'center',
    width: 'max-content',
    paddingRight: 18,
  },
  submitBtn: {
    cursor: 'pointer',
    fontSize: 12,
    color: 'white',
    padding: '8px 22px 8px 22px',
    border: 'none',
    backgroundColor: COLORS.GREEN,
    '-webkit-appearance': 'none',
    '&:hover': {
      backgroundColor: COLORS.GREEN,
      boxShadow: 'none',
    },
    '@media (max-width:600px)': {
      marginLeft: 20,
    },
  },
  iconSubmit: {
    height: 16,
    width: 10,
  },
  inputBtn: {
    // marginTop: 20,
    border: '1px solid',
    display: 'inline-block',
    padding: '6px 12px',
    cursor: 'pointer',
    backgroundColor: '#fff',
    fontSize: 12,
  },
  inputsSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    paddingRight: 40,
    '@media (max-width:600px)': {
      paddingRight: 0,
      alignItems: 'flex-start',
    },
  },
  inputButtonsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-start',
    marginBottom: 10,
    '@media (max-width:600px)': {
      marginLeft: 20,
    },
  },
}))

const Careers = ({ data }) => {
  const classes = useStyles()
  const orderedData =
    data.allContentfulGreenMetalsOrderedList.edges[0].node.items
  const textSection1 = orderedData.filter(
    data => data.identifier === 'Careers Page Text Section 1'
  )[0]
  const textSection2 = orderedData.filter(
    data => data.identifier === 'Careers Page Text Section 2'
  )[0]
  const vacancies = data.allContentfulVacancies.edges

  const [state, setState] = useState({
    fullName: '',
    email: '',
    message: '',
  })

  const [selectedFile, setSelectedFile] = useState()
  const [msg, setMsg] = useState('Max 4 mb')
  const [isSuccess, setIsSuccess] = useState(false)

  const handleChange = ({ target }) => {
    const { value, id } = target
    setState({
      ...state,
      [id]: value,
    })
  }

  const handleFileChange = event => {
    if (event.target.files.length > 0) {
      setSelectedFile(event.target.files[0])
      console.log(event.target.files[0])
      setMsg(event.target.files[0].name)
    }
  }

  const validateSelectedFile = () => {
    const MAX_FILE_SIZE = 4000 // 5MB

    if (!selectedFile) {
      setIsSuccess(false)
      return false
    }

    const fileSizeKiloBytes = selectedFile.size / 1000

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      setMsg('File size max 4mb')
      setIsSuccess(false)
      return false
    }

    setMsg('Max 4 mb')
    setIsSuccess(true)
    return true
  }

  const handleSubmit = event => {
    event.preventDefault()
    // if (validateSelectedFile()) {
    //   alert('Form submitted successfully')
    //   // Clear the form fields
    //   setState({
    //     fullName: '',
    //     email: '',
    //     message: '',
    //   })
    //   setSelectedFile(null)
    //   console.log('Submitted the form')
    // }
    if (!validateSelectedFile()) {
      return
    }
    const formData = new FormData()
    formData.append('form-name', 'work') // this ties the submission to the "work" form
    formData.append('fullName', state.fullName)
    formData.append('email', state.email)
    formData.append('message', state.message)
    formData.append('file-upload', selectedFile)

    fetch('/careers', {
      // make a POST request to the same path as the form
      method: 'POST',
      body: formData,
    })
      .then(() => {
        setIsSuccess(true)
        alert('Form submitted successfully')

        // Clear the form fields
        setState({
          fullName: '',
          email: '',
          message: '',
        })
        setSelectedFile(null)
        setMsg('Max 4 mb')
        console.log('Submitted the form')
      })
      .catch(error => {
        setIsSuccess(false)
        alert('Form submission failed: ', error)
      })
  }

  return (
    <Layout>
      <SubPageHeroBanner
        textPosition="Left"
        image={data.solutions.childImageSharp.fluid}
        title="Careers"
      />
      <div className={classes.root}>
        <Grid container className={classes.contentContainer}>
          {/* TODO:See textarea style in each component disabled height 300 */}
          <TextArea
            textLeft={textSection1.title}
            textRight={textSection1.description.description}
          />

          <Grid container className={classes.openPositionsContainer}>
            <Grid item xs={12} sm={4} className={classes.sectionLeft}>
              <h2 className={`${classes.text} ${classes.positionsHeader}`}>
                Open positions
              </h2>
              <div>
                {vacancies.map((vacancy, i) => {
                  return (
                    <div key={i}>
                      <h3 className={`${classes.text} ${classes.description}`}>
                        {vacancy.node.title}
                      </h3>
                      <a
                        href={vacancy.node.linkUrl}
                        target="_blank"
                        rel="noreferrer"
                        className={classes.linkAnchor}
                      >
                        <p className={classes.linkText}>
                          {vacancy.node.linkText}
                        </p>
                      </a>
                    </div>
                  )
                })}
              </div>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.sectionRight}>
              <img
                src={carreerImg}
                alt="meeting"
                className={classes.careerImg}
              />
            </Grid>
          </Grid>

          <Grid container className={classes.submitApplicationContainer}>
            <Grid item xs={12} sm={4} className={classes.sectionLeft}>
              <h2 className={`${classes.textAlt} ${classes.positionsHeader}`}>
                Submit an open application
              </h2>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              className={classes.sectionRightEmpty}
            ></Grid>
          </Grid>

          <form
            name="work"
            method="POST"
            netlify-honeypot="bot-field"
            data-netlify="true"
            enctype="multipart/form-data"
            onSubmit={handleSubmit}
            className={classes.applicationForm}
            // action="/work-with-us"
            // action={action}
          >
            <Grid container className={classes.applyContainer}>
              <Grid item xs={12} sm={4} className={classes.inputsSection}>
                <input type="hidden" name="form-name" value="work" />
                <input
                  id="fullName"
                  name="fullName"
                  type="text"
                  placeholder="Name"
                  onChange={handleChange}
                  className={classes.input}
                  value={state.fullName}
                />
                <br />
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email"
                  onChange={handleChange}
                  className={classes.input}
                  value={state.email}
                />
              </Grid>
              <Grid item xs={12} sm={4} className={classes.quoteSectionLefts}>
                <textarea
                  id="message"
                  name="message"
                  rows="12"
                  placeholder="Message"
                  className={classes.message}
                  onChange={handleChange}
                  value={state.message}
                />
              </Grid>

              <Grid item xs={12} sm={2} className={classes.quoteSectionLefts}>
                <div className={classes.containerSubmit}>
                  {/* TODO: If wanted add cv file, add this part and check file size for it */}
                  <div className={classes.inputButtonsWrapper}>
                    <label htmlFor="file-upload" className={classes.inputBtn}>
                      Upload file
                    </label>
                    <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      onChange={handleFileChange}
                      style={{ display: 'none' }}
                    />
                    <span
                      style={{
                        paddingLeft: 4,
                        color: 'grey',
                        fontSize: 10,
                      }}
                    >
                      {msg}
                    </span>
                  </div>
                  <div style={{ width: 135 }}>
                    <input
                      type="submit"
                      value="Submit"
                      onClick={validateSelectedFile}
                      className={classes.submitBtn}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </form>

          <Grid container className={classes.quoteContainer}>
            <Grid item xs={12} sm={6} className={classes.quoteSectionLeft}>
              <img src={cecilie} alt="meeting" className={classes.careerImg2} />
            </Grid>
            <Grid item xs={12} sm={4} className={classes.quoteSectionright}>
              <cite className={classes.quote}>
                We offer a warm and inclusive culture where you can learn,
                evolve and have fun while making real impact for our planet.
              </cite>
              <p className={classes.quoteDetail} style={{ marginTop: '20px' }}>
                Cecilie Jonassen
              </p>
              <p className={classes.quoteDetail}>CEO VOW Green Metals</p>
              <FormatQuote
                color="#00AC4D"
                style={{
                  color: '#00AC4D',
                  marginTop: '20px',
                  fontSize: 60,
                  transform: `rotate(-180deg)`,
                }}
              />
            </Grid>
          </Grid>

          <TextArea
            textLeft={textSection2.title}
            textRight={textSection2.description.description}
          />
        </Grid>
      </div>
    </Layout>
  )
}

export default Careers

export const query = graphql`
  query CareerPageQuery {
    solutions: file(relativePath: { eq: "sub_hero_careers.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    career: file(relativePath: { eq: "vow-career.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allContentfulGreenMetalsOrderedList(
      filter: { listId: { eq: "careersPageContent" } }
    ) {
      edges {
        node {
          items {
            ... on ContentfulGreenMetalsTextSection {
              id
              identifier
              title
              description {
                description
              }
            }
          }
        }
      }
    }
    allContentfulVacancies {
      edges {
        node {
          linkText
          linkUrl
          title
        }
      }
    }
  }
`
